// @ts-ignore
import { querySelectorAll } from 'common/js/utils/dom';
// @ts-ignore
import { showModal } from 'common/js/modal/modal';
// @ts-ignore
import { getEventFromDatasetAtIndex } from 'common/js/parts/tracking';

const domScriptTemplatePopup = querySelectorAll('script[data-js-popup="leave"]');

const convertToSlug = (name: string) => name.toLowerCase().replace(/([^\w ]|\s)+/g, '');

if (domScriptTemplatePopup.length) {
    const affLinks = querySelectorAll('[data-js-clickout]');

    const leaveBehindTargetBrandsSet: string[] = [];

    domScriptTemplatePopup.forEach((popup) => {
        let targetBrands = popup.getAttribute('data-js-target-products')?.split(',');

        targetBrands = targetBrands
            ? targetBrands.map(convertToSlug).filter((v) => !leaveBehindTargetBrandsSet.includes(v))
            : [];

        if (targetBrands.length) {
            affLinks.forEach((affLink) => {
                const brandName = getEventFromDatasetAtIndex(affLink.dataset, 'el') || affLink.getAttribute('title') || '';
                // convert full brand name to brand slug because full brand name is
                // stored in afflink but list of brand slug comes from elastic search
                const affLinkBrandSlug = convertToSlug(brandName);

                if (targetBrands?.includes(affLinkBrandSlug)) {
                    affLink.addEventListener('click', (e) => {
                        if (e.button !== 1) {
                            showModal(popup);
                        }
                    });
                }
            });
            leaveBehindTargetBrandsSet.push(...targetBrands);
        }
    });
}

export {};
